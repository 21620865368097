import React from 'react';

// sudo lsof -nP -iTCP:8000 -sTCP:LISTEN

import { ThemeProvider, StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import { Helmet } from 'react-helmet';
import { Location } from '@reach/router';

import Header from '../Header';
import HomeHeader from '../HomeHeader';
import Footer from '../Footer';
// import ThemeSwitch from '../ThemeSwitch';

// import ContactModal from '../ContactModal';
// import AboutModal from '../AboutModal';

import { /*device,*/ theme as baseTheme } from '../../utils';
// import GlobalContext from '../../context/GlobalContext';
import GlobalStyle from '../../styles/globalStyle';
// import '../../styles/common.scss';

import favicon16 from '../../assets/favicon/favicon-16x16.png';
import favicon32 from '../../assets/favicon/favicon-32x32.png';
import apple from '../../assets/favicon/apple-touch-icon.png';
// import manifest from '../../assets/favicon/site.webmanifest'; // need special loader for this
import safari from '../../assets/favicon/safari-pinned-tab.svg';

import { get, merge } from 'lodash';

const SITE_URL = 'https://chairliftcreative.com';
const HEAD_DATA = {
  siteTitle: 'Chairlift Creative - Crested Butte and Gunnison Website Design &#038; Development ',
  siteDesc: 'Chairlift Creative is a full-service website design & development workshop located in Crested Butte, Colorado. We\'re experts at everything website-releated, including ecommerce, SEO optimization, photo / video for the web, hosting, and almost anything else you can think of. Get in touch and let\'s talk about your project!',
  pageTitle: 'Chairlift Creative - Crested Butte and Gunnison Website Design &#038; Development',
  pageDesc: 'Chairlift Creative is a full-service website design & development workshop located in Crested Butte, Colorado. We\'re experts at everything website-releated, including ecommerce, SEO optimization, photo / video for the web, hosting, and almost anything else you can think of. Get in touch and let\'s talk about your project!',
};

// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  return true;
};

const modes = { light: 'light', dark: 'dark' };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) => {
  return merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors)
  });
};

const Layout = ({ children, location }) => {
  // const gContext = useContext(GlobalContext);
  const isHomePage = location.pathname === '/';

  // useEffect(() => {
  //   window.addEventListener(
  //     "popstate",
  //     function(event) {
  //       // The popstate event is fired each time when the current history entry changes.
  //       gContext.closeAbout();
  //       gContext.closeContact();
  //     },
  //     false
  //   );
  //   window.addEventListener(
  //     "pushState",
  //     function(event) {
  //       // The pushstate event is fired each time when the current history entry changes.
  //       gContext.closeAbout();
  //       gContext.closeContact();
  //     },
  //     false
  //   );
  //   return () => {};
  // }, [gContext]);

  console.log(HEAD_DATA)

  return (
    <Location>
      {({ location }) => (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <ThemeProvider
            theme={
              getTheme(modes.dark)
              // gContext.theme.bodyDark ? getTheme(modes.dark) : getTheme(modes.light)
            }
          >
            <GlobalStyle />

            <Helmet>
              <html lang="en" id="home" />

              <meta content="IE=edge" httpEquiv="X-UA-Compatible" />

              <meta
                name="viewport"
                content="user-scalable=yes, initial-scale=1, maximum-scale=5, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi"
              />

              <meta
                content={HEAD_DATA.siteTitle}
                name="apple-mobile-web-app-title"
              />
              <meta content={HEAD_DATA.pageTitle} property="og:title" />
              {/* <meta content={ HEAD_DATA.pageTitle } name="twitter:title" /> */}

              <title>{HEAD_DATA.pageTitle}</title>

              <meta
                content={
                  HEAD_DATA.pageDesc ? HEAD_DATA.pageDesc : HEAD_DATA.siteDesc
                }
                name="description"
              />
              <meta
                content={
                  HEAD_DATA.pageDesc ? HEAD_DATA.pageDesc : HEAD_DATA.siteDesc
                }
                property="og:description"
              />
              {/* <meta content={ HEAD_DATA.pageDesc ? HEAD_DATA.pageDesc : HEAD_DATA.siteDesc } name="twitter:description" /> */}

              <meta content="yes" name="apple-mobile-web-app-capable" />
              <meta
                content="black-translucent"
                name="apple-mobile-web-app-status-bar-style"
              />
              <meta name="theme-color" content={ location.pathname === '/contact/' ? '#141620' : '#fffdf5'} />
              <meta content={HEAD_DATA.siteTitle} name="application-name" />

              <meta content="website" property="og:type" />
              <meta content={HEAD_DATA.siteTitle} property="og:site_name" />
              {/* <meta content={social.fbAppId} property="fb:app_id" /> */}

              {/* <meta content="summary_large_image" name="twitter:card" />
                <meta content={`@${ SOCIAL.twitter }`} name="twitter:site" />
                <meta content={`@${ SOCIAL.twitter }`} name="twitter:creator" />
                <meta content={ HEAD_DATA.pageTitle } name="twitter:text:title" /> */}

              <meta
                content={SITE_URL + (location.pathname || '')}
                property="og:url"
              />
              {/* <meta content={ SITE_URL + (location?.pathname || '') } name="twitter:url" /> */}
              <link
                rel="preconnect canonical"
                href={SITE_URL + (location.pathname || '')}
              />

              {/* <meta content={`${SITE_URL}${imageUrl}` || `${SITE_URL}/gatsby-social-img.png`} property="og:image" />
                <meta content="500" property="og:image:width" />main
                <meta content="150" property="og:image:height" />

                <meta content={`${SITE_URL}${imageUrl}` || `${SITE_URL}/gatsby-social-img.png`} name="twitter:image" />
                <meta content="500" name="twitter:image:width" />
                <meta content="150" name="twitter:image:height" /> */}

              <link rel="icon" type="image/png" href={favicon16} />
              {/* Favicon - https://realfavicongenerator.net */}
              <link rel="apple-touch-icon" sizes="180x180" href={apple} />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={favicon32}
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={favicon16}
              />
              {/* <link rel="manifest" href={ manifest } /> */}
              <link rel="mask-icon" href={safari} color="#5bbad5" />
              <meta name="msapplication-TileColor" content="#603cba" />
            </Helmet>

            <div className="site-wrapper overflow-hidden">
              {/* ref={ eleRef } */}
              {isHomePage ? <HomeHeader /> : <Header />}
              {children}
              <Footer isDark={true} />
            </div>

            {/* <AboutModal />

              <ContactModal /> */}
          </ThemeProvider>
        </StyleSheetManager>
      )}
    </Location>
  );
};

export default Layout;
